button {
	box-shadow:inset 0px 1px 0px 0px #ffffff;
	background:linear-gradient(to bottom, #ededed 5%, #dfdfdf 100%);
	background-color:#ededed;
	border-radius: 100px;
	// border:1px solid #dcdcdc;
	display:inline-block;
	cursor:pointer;
	color:#777777;
	font-family:Arial;
	font-size:15px;
	font-weight:bold;
	text-decoration:none;
    text-shadow:0px 1px 0px #ffffff;
    
    text-align: center;
    height: 3rem;
    width: 3rem;

    &:hover {
        background:linear-gradient(to bottom, #dfdfdf 5%, #ededed 100%);
        background-color:#dfdfdf;
    }

    &:active {
        position:relative;
        top:1px;
    }
}

input#current {
    margin: 0 1rem 0 1rem;
    height: 2rem;
    width: 10rem;
    text-align: center;
    font-weight: 900;
    font-size: 1rem;
}